import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { createSelector } from "reselect";
import { getBot, getResult } from "store/actions";
import Breadcrumbs, { DASHBOARD_ITEM } from "components/Common/Breadcrumb";
import MiniWidget from "./MiniWidget";
import TableInfo from "components/Common/TableInfo";
import BotStatus from "pages/BotTrader/Bots/BotStatus";
import BotIcon from "pages/BotTrader/Bots/BotIcon";
import DateFromNow from "components/Common/DateFromNow";
import { profitLastWeek } from "./ResultsHelper";

//Bitcoin Chart
const series1 = [
    { name: "BTC", data: [12, 14, 2, 47, 42, 15, 47, 75, 65, 19, 14] },
  ]
const options1 = {
chart: { sparkline: { enabled: !0 } },
stroke: { curve: "smooth", width: 2 },
colors: ["#f1b44c"],
fill: {
    type: "gradient",
    gradient: {
    shadeIntensity: 1,
    inverseColors: false,
    opacityFrom: 0.45,
    opacityTo: 0.05,
    stops: [25, 100, 100, 100],
    },
},
tooltip: { fixed: { enabled: false }, x: { show: false }, marker: { show: false } },
}

const reports = [
    {
      title: "Last Week",
      icon: "mdi mdi-calendar-week",
      color: "warning",
      value: "$ 9134.39",
      desc: "+ 0.0012 ( 0.2 % )",
      series: series1,
      options: options1,
      arrowUpDown: 'mdi mdi-arrow-up ms-1 text-success'
    },
    {
      title: "This Month",
      icon: "mdi mdi-calendar-month",
      color: "primary",
      value: "$ 245.44",
      desc: "- 4.102 ( 0.1 % )",
      series: series1,
      options: options1,
      arrowUpDown: 'mdi mdi-arrow-down ms-1 text-danger'
    },
    {
        title: "Yearly Forecast",
        icon: "mdi mdi-chart-line",
        color: "info",
        value: "$63.61",
        desc: "+ 1.792 ( 0.1 % )",
        series: series1,
        options: options1,
        arrowUpDown: 'mdi mdi-arrow-up ms-1 text-success'
      },
];

const ResultPage = () => {

    const dispatch = useDispatch();

    const {id} = useParams();
    const { result, loadingResult } = useSelector(createSelector((state: any) => state.results, (results: any) => ({result: results.result, loadingResult: results.loading})));
    const { bot, loadingBot } = useSelector(createSelector((state: any) => state.bots, (bots: any) => ({bot: bots.bot, loadingBot: bots.loading})));
    const [botInfo, setBotInfo] = useState(null);
    const [lastWeek, setLastWeek] = useState(null);

    useEffect(() => {
        dispatch(getResult(id));
        dispatch(getBot(id));
    }, []);

    useEffect(() => {
        if (result && bot) {
            setBotInfo([
                {
                    id: "server",
                    name: "Broker Server",
                    value: bot.server,
                },
                {
                    id: "accountNumber",
                    name: "Account #",
                    value: bot.loginFull || bot.login,
                },
                {
                    id: "eaName",
                    name: "Robot",
                    value: bot.eaName
                },
                {
                    id: "symbol",
                    name: "Symbol",
                    value: `${bot.symbol} (${bot.period})`
                },
                {
                    id: "status",
                    name: "Status",
                    component: <BotStatus bot={bot}/>
                },
                {
                    id: "runtime",
                    name: "Runtime",
                    component: <DateFromNow dateTime={bot.createdAt}/>
                },

                {
                    id: "lastUpdate",
                    name: "Last Update",
                    component: <DateFromNow dateTime={bot.lastModified}/>
                },
            ]);
            setLastWeek(profitLastWeek(result.trades));
        }
    }, [result, bot]);
        
    return <div className="page-content">
        <Container fluid>
            <Breadcrumbs title="Results" items={[DASHBOARD_ITEM]} />
            <Row>
                <Col sm="3">
                    <Card>
                        <CardBody>
                            <div className="d-flex justify-content-center pt-2 pb-3">
                                <BotIcon bot={bot}/>
                                <p className="text-muted p-3">{bot.description}</p>
                            </div>
                            <TableInfo data={botInfo}/>
                        </CardBody>
                    </Card>
                </Col>
                <Col sm="9">
                    <Row>
                        <Col sm="6">
                            <MiniWidget report={reports[0]} />
                        </Col>
                        <Col sm="6">
                            <MiniWidget report={reports[1]} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <MiniWidget report={reports[2]} />
                        </Col>
                    </Row>
                </Col>
            </Row>

            {/* <CardsMini/> */}

                <pre>{lastWeek && JSON.stringify(lastWeek, null, 4)}</pre>
                <pre>{JSON.stringify(result, null, 4)}</pre>
            {/* <Row>
                <Col sm="3">
                    <Card>
                        <CardBody>
                            <CardTitle>Balance</CardTitle>

                        </CardBody>
                    </Card>

                    <Card>
                        <CardBody>
                            <CardTitle>Your Referrer</CardTitle>
                            
                        </CardBody>
                    </Card>
                </Col>
                <Col>
                    
                </Col>
            </Row> */}
        </Container>
    </div>


}

export default ResultPage;
import moment from "moment";


function calcForecast(profitAmount, startAmount, length, minBalance): any[] {
    let lotSize = Math.floor(startAmount / minBalance);
    const profitPerLot = profitAmount / lotSize;
    let amount = startAmount;
    const data = [];
    for (let count = 0; count < length; count++) {
        data.push({value: amount, lotSize, profitAmount});
        lotSize = Math.floor(amount / minBalance);
        amount += (profitPerLot * lotSize);
    }
    return data;
}


export const profitLastWeek = (trades: any[]): any => {
    const data = [];
    let currentDate = moment().subtract(1, "week");
    let weekStart = currentDate.clone().startOf('week');
    let weekEnd = currentDate.clone().endOf('week');

    console.log("ws", weekStart.toISOString());
    console.log("we", weekEnd.toISOString());
    return [];
}